<template>
    <div class="z-10000 h-screen w-screen relative flex items-end">
        <video-background 
                src="/videos/logoReveal_without_slogan.mp4"
                style="height: 100vh; background-color: black;"
                :loop="false"
                @ended="endedLogoReveal"
            >
        </video-background>
    </div>
</template>
<style>
    .h-screen{
        height: calc(var(--vh, 1vh) * 100);
    }
</style>
<script>

export default {
    name: "LogoReveal",
    methods:{
        endedLogoReveal(){
            this.$emit('ended')
        }
    },
    setup(){
        let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
}
</script>